import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Libary
import ElementPlus from "element-plus";
import i18n from "./plugins/vue-i18n";
import { pageTitle } from "vue-page-title";
import MasonryWall from "@yeger/vue-masonry-wall";

// Sentry
// import * as Sentry from "@sentry/vue";

// style
import "element-plus/dist/index.css";
import "@/assets/style/components.scss";
import "@/assets/style/style.scss";

// import { getFirestore, addDoc, collection } from "firebase/firestore";

// Firebase
import { initializeApp } from "firebase/app";
// import helpers from "./helpers/global";
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
initializeApp(firebaseConfig);

const app = createApp(App);

// app.provide("$store", store);
app.use(store);

app.use(i18n);
app.use(router);
app.use(pageTitle({ router, mixin: true }));
app.use(ElementPlus);
app.use(MasonryWall);

/*/
Sentry.init({
  app,
  // dsn: "https://f41cadfb496095f315d27b509c6dd63d@o4507864928092160.ingest.us.sentry.io/4507865207734272",
  dsn: "https://3e0464dc4b4486cd86ca78a2452b87ce@o919786.ingest.us.sentry.io/4507897827360768",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    // Sentry.browserProfilingIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/staging-app\.myculture\.ai/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Profiling
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
});
//*/

/*
app.config.errorHandler = (err: any, instance, info) => {
  let userData = {} as any;
  let email = "";
  const userDataEncoded: any = localStorage.getItem("userData");
  if (userDataEncoded) {
    try {
      userData = JSON.parse(atob(userDataEncoded) || "{}");
      email = userData.email;
    } catch (e: any) {
      // console.error(e);
    }
  }

  // Get IP of client
  const doGetIp = false;
  if (doGetIp) {
    fetch("https://api.ipify.org?format=json")
      .then((x) => x.json())
      .then(({ ip }) => {
        const errorDetails = {
          error: {
            message: err.message,
            stack: err.stack,
          },
          info: info,
          email: email,
          ip: ip,
          created_at: helpers.now(),
        };
        const fs = getFirestore();
        addDoc(collection(fs, "errors"), errorDetails);
        console.error(errorDetails.error.message);
        console.error(errorDetails.error.stack);
      });
  } else {
    const ip = "127.0.0.1";
    const errorDetails = {
      error: {
        message: err.message || "Unknown error",
        stack: err.stack || "Unknown stack",
      },
      info: info,
      email: email,
      ip: ip,
      created_at: helpers.now(),
    };
    // console.log(errorDetails, "errorDetails");
    const fs = getFirestore();
    addDoc(collection(fs, "errors"), errorDetails);
    // console.error(errorDetails.error.message);
    // console.error(errorDetails.error.stack);
  }
};
//*/

app.mount("#app");
